


























































import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from '@/layouts/back-office/elements/Card.vue';
import {snapshot_inspection, snapshot_inspection_parameterValues} from '@/types/intrador';
import meta, {metaExists} from '@/filters/vue/meta';

@Component({
  components: {Card},
})
export default class SnapshotParameters extends Vue {
  @Prop(Object) protected inspection!: snapshot_inspection;

  get parameterValues() {
    if (this.inspection.parameterValues === null) {
      return [];
    }

    return this.inspection.parameterValues
      .filter((pv) => pv && pv.parameter && pv.parameter.type !== 'image');
  }

  get assetUniqueNumber(): string | null {
    return meta(this.inspection.asset, this.$store.getters.user.entity.config.assetUniqueIdentifier);
  }

  private metaExists(key: string): boolean {
    return metaExists(this.inspection.asset, key);
  }

  private option(parameterValue: snapshot_inspection_parameterValues) {
    if (!parameterValue.value || !parameterValue.parameter) {
      return;
    }

    if (parameterValue.parameter.parameterOptions) {
      const option = parameterValue.parameter.parameterOptions.find((po) => po && po.id === parameterValue.value);
      if (option) {
        return option.value;
      }
    }

    return parameterValue.value;
  }
}
