

















































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import {
  AuditInspections_paginateAuditInspections_data,
  EquipmentSituation,
  InspectionStatus,
  PaginateAudits_paginateAudits_data,
} from '@/types/intrador';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
import InspectionStatusLabel from '@/components/inspection/InspectionStatusLabel.vue';
import CloseAuditAction from '@/components/audits/actions/CloseAuditAction.vue';
import DemoAnomalyView from '@/pages/audits/dashboard/DemoAnomalyView.vue';
import SoldAnomalyView from '@/pages/audits/dashboard/SoldAnomalyView.vue';
import RentalAnomalyView from '@/pages/audits/dashboard/RentalAnomalyView.vue';
import ImageViewer from '@/components/ImageViewer.vue';
import SnapshotImages from '@/components/snapshot/SnapshotImages.vue';
import SnapshotParameters from '@/components/snapshot/SnapshotParameters.vue';
import InspectionRelated from '@/components/inspection/InspectionRelated.vue';
import Score from '@/components/planning/partials/Score.vue';
import InspectionInspector from '@/components/inspection/InspectionUserView.vue';
import EquipmentSituationStatus from '@/components/snapshot/EquipmentSituationStatus.vue';
import InspectionAnomalies from '@/components/inspection/InspectionAnomalies.vue';
import AnomaliesAmount from '@/components/inspection/anomalies/AnomaliesAmount.vue';

@Component({
  components: {
    SoldAnomalyView,
    DemoAnomalyView,
    RentalAnomalyView,
    InspectionStatusLabel,
    Modal,
    Spinner,
    CloseAuditAction,
    SnapshotImages,
    ImageViewer,
    SnapshotParameters,
    InspectionRelated,
    InspectionAnomalies,
    InspectionInspector,
    Score,
    EquipmentSituationStatus,
    AnomaliesAmount,
  },
})

  export default class ReviewAuditAction extends Vue {
    @Prop(Object) protected audit!: PaginateAudits_paginateAudits_data;
    @Prop(String) protected situationTitle!: string;

    private selected: AuditInspections_paginateAuditInspections_data | null = null;
    private checkedInspections: any[] = [];
    private currentTab: number = 0;
    private showInspections: boolean = true;
    private noData: boolean = false;
    private dataInspections?: AuditInspections_paginateAuditInspections_data[] | null = [];

    public open() {
      if (this.situationTitle) {
        this.currentTab = this.tabs.findIndex((tab) =>
          tab.title === this.situationTitle,
        );
      } else {
        this.currentTab = 0;
      }

      this.modal.open();
    }

    public close() {
      this.dataInspections = [];
      this.modal.close();
    }

    private openImage(id: string) {
      const viewer = this.$refs.viewer as ImageViewer;
      viewer.open(id);
    }

    private amInspecting(event: MouseEvent) {
      if (this.currentTab === 5) {
        this.closeAuditAction();
      }
      this.currentTab = 5;
      this.showInspections = false;
      (event.currentTarget as HTMLInputElement).blur();
    }

    private noInspections(data: any) {
      this.dataInspections = data.paginateAuditInspections.data;

      this.noData = !this.dataInspections || this.dataInspections.length === 0;

      return data;
    }

    private get variables() {
      if (this.currentTab < 5) {
        return {
          auditId: this.audit.id,
          anomaly: this.tabs[this.currentTab].anomaly,
          equipmentSituation: this.tabs[this.currentTab].situation,
          status: this.tabs[this.currentTab].status,
        };
      } else {
        return {
          auditId: this.audit.id,
          anomaly: false,
        };
      }
    }

    private get tabs(): any[] {
      return [
        {
          id: 'SOLD',
          title: this.$it('equipment-situation.sold.title', 'Sold') as string,
          situation: EquipmentSituation.SOLD,
        },
        {
          id: 'RENTAL',
          title: this.$it('equipment-situation.rental.title', 'Rental') as string,
          situation: EquipmentSituation.RENTAL,
        },
        {
          id: 'IN_DEMONSTRATION',
          title: this.$it('equipment-situation.demo.title', 'Demo') as string,
          situation: EquipmentSituation.IN_DEMONSTRATION,
        },
        {
          id: 'EXPIRED',
          title: this.$it('inspection.status.expired.title', 'Expired') as string,
          status: InspectionStatus.EXPIRED,
        },
        {
          id: 'ANOMALIES',
          title: this.$it('planning.card.anomalies.title', 'Anomalies') as string,
          anomaly: true,
        },
      ];
    }

    private get modal() {
      return this.$refs.modal as Modal;
    }

    private hasInspectionStatus(inspection: AuditInspections_paginateAuditInspections_data) {
      return inspection.status === 'ACCEPTED' || inspection.status === 'DECLINED';
    }

    private openInspection(inspection: any) {
      return this.selected = !(this.selected === inspection) && inspection;
    }

    private closeAuditAction() {
      this.$emit('closeAuditAction');
      this.close();
    }

    private nextStatus(event: MouseEvent) {
      this.checkedInspections = [];
      let n = this.currentTab;
      n++;
      this.currentTab = n;
      (event.currentTarget as HTMLInputElement).blur();
    }

    private previousStatus(event: MouseEvent) {
      this.checkedInspections = [];
      let n = this.currentTab;
      n--;
      this.currentTab = n;
      this.showInspections = true;
      (event.currentTarget as HTMLInputElement).blur();
    }

    private approveInspection(inspection: any | any[], type: string) {
      this.$emit('approveInspection', inspection, type);
    }
  }

