import { Component, Prop, Vue } from 'vue-property-decorator';
import { snapshot_inspection_checks } from '@/types/intrador';

@Component
export default class AnomalyViewMixin extends Vue {
  protected namespace = '';
  protected message = '';
  protected level = '';

  // TODO: This type should be more concrete, fix datasource
  @Prop(Array) private readonly checks!: snapshot_inspection_checks[];

  /**
   * Get the SoldDate::SOLD_DATE_FROM_SUBMIT Check
   *
   * @private
   */
  private get check() {
    return this.checks.find(
      (i) => i.namespace === this.namespace && i.message === this.message && i.level === this.level);
  }

  /**
   * Get the check attributes
   *
   * @private
   */
  get attributes(): any {
    return this.check && this.check.attributes ? JSON.parse(this.check.attributes) : null;
  }
}
