



































































































































































































import {Component, Mixins} from 'vue-property-decorator';
import {
  AuditStatus,
  EquipmentSituation,
  InspectionApprovalState,
  InspectionTypeEnum,
  PaginateAudits_paginateAudits_data,
  PaginateAuditsVariables,
} from '@/types/intrador';
import CloseAuditAction from '@/components/audits/actions/CloseAuditAction.vue';
import ReviewAuditAction from '@/components/audits/actions/review/ReviewAuditAction.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
import QueryDataTable from '@/layouts/back-office/elements/datatable/QueryDataTable.vue';
import {DataTableColumn, DataTableSearchable} from '@/layouts/back-office/elements/datatable/DataTableMixin';
import MetaList from '@/components/MetaList.vue';
import Score from '@/components/planning/partials/Score.vue';
import ApproveInspections from '@/components/inspection/actions/ApproveInspections.vue';
import LastAuditRating from '@/components/planning/partials/LastAuditRating.vue';
import AccountManagerFilterMixin from '@/filters/dataTable/AccountManagerFilterMixin';
import {Filter, FilterType} from '@/layouts/back-office/elements/filters/FilterMixin';
import NumberCard from '@/components/dealer/cards/NumberCard.vue';
import {VueFrappe} from 'vue2-frappe';
import {StatisticsGroup, StatisticsPartition} from '@/types';
import {Meta} from '@sophosoft/vue-meta-decorator';
import AnomaliesAmount from '@/components/inspection/anomalies/AnomaliesAmount.vue';
import EquipmentSituationNumberCard from '@/components/audits/cards/EquipmentSituationNumberCard.vue';
import OnHoldLabel from '@/components/audits/OnHoldLabel.vue';

@Component({
  components: {
    OnHoldLabel,
    AnomaliesAmount,
    EquipmentSituationNumberCard,
    NumberCard,
    LastAuditRating,
    Score,
    MetaList,
    QueryDataTable,
    CloseAuditAction,
    ReviewAuditAction,
    ApproveInspections,
    Spinner,
    VueFrappe,
  },
})

export default class Dashboard extends Mixins(AccountManagerFilterMixin) {
  private selected: PaginateAudits_paginateAudits_data | null = null;
  private situationTitle: string | null = null;
  private inspectionType: string = InspectionTypeEnum.Snapshot;
  private stateAccept = InspectionApprovalState.ACCEPTED;
  private stateDecline = InspectionApprovalState.DECLINED;
  private selectedItem: any[] | null = null;
  private elementVisible: boolean = true;

  private inDemoCount: number = 0;
  private inSoldCount: number = 0;
  private inRentalCount: number = 0;
  private toPlanCount: number = 0;
  private inProgressCount: number = 0;
  private inReviewCount: number = 0;
  private resolvedAmount: number = 0;

  public get variables(): PaginateAuditsVariables {
    return {
      status: AuditStatus.IN_REVIEW,
      // accountManagerIds: this.accountManagerIds,
      searchableFields: [
        'branch.name',
        'dealer.name',
      ],
    };
  }

  private get statisticsVariables(): any {
    const currentYear = (new Date()).getFullYear();
    const currentMonth = (new Date()).getMonth() + 1;
    const lastDayOfMont = (new Date(currentYear, currentMonth, 0)).getDate();

    return {
      // accountManagerIds: this.accountManagerIds,
      auditStatus: 'IN_REVIEW',
      date: {
        start: `${currentYear}-${currentMonth}-01T00:00:00Z`,
        end: `${currentYear}-${currentMonth}-${lastDayOfMont}T23:59:59Z`,
      },
    };
  }

  public refetch() {
    (this.$refs.queryDataTable as QueryDataTable).refresh();
    if (this.$refs.query) {
      (this.$refs.query as any).getApolloQuery().refetch();
    }
  }

  private mounted() {
    this.$nextTick(() => {
      this.refetch();
    });
  }

  private created() {
    setTimeout(() => this.elementVisible = false, 5000);
  }

  get chartData() {
    return [
      {
        values: [this.toPlanCount, this.inProgressCount, this.inReviewCount],
        chartType: 'bar',
        name: '',
      },
    ];
  }

  get maxChartHeight() {
    return this.chartData.reduce((a: any, b: any) => {
      return a.values > b.values ? a.values : b.values;
    }, 0)[0];
  }

  private onDataReceived(data: any) {
    this.inReviewCount = data.paginateAudits.total;
  }

  private onStatisticsReceived(counts: any) {
    if (counts) {
      this.inDemoCount = counts.inspectionStatistics.bySituation
        .find((situation: StatisticsPartition) => situation.name === EquipmentSituation.IN_DEMONSTRATION)?.count ?? 0;
      this.inSoldCount = counts.inspectionStatistics.bySituation
        .find((situation: StatisticsPartition) => situation.name === EquipmentSituation.SOLD)?.count ?? 0;
      this.inRentalCount = counts.inspectionStatistics.bySituation
        .find((situation: StatisticsPartition) => situation.name === EquipmentSituation.RENTAL)?.count ?? 0;

      this.toPlanCount = counts.paginateBranches.total;
      this.inProgressCount = counts.auditStatistics.byStatus.find(
        (status: StatisticsPartition) => status.name === AuditStatus.IN_PROGRESS)?.count ?? 0;

      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString();
      const name = year + '-' + month;

      const statusThisMonth = counts.auditStatistics.byStatusByMonth
        .find((status: StatisticsGroup) => status.name === name);
      if (statusThisMonth) {
        this.resolvedAmount = statusThisMonth.partitions
          .find((status: StatisticsPartition) => status.name === AuditStatus.CLOSED)?.count ?? 0;
      }

      return counts;
    }
  }

  private inspectionAmount(item: any, column: any) {
    const rowItem = item.inspectionSituationCount.find((row: any) =>
      row.name.toLowerCase() === column.key.toLowerCase());

    if (rowItem) {
      return rowItem.count;
    } else {
      return 0;
    }
  }

  /**
   * The DataTable searchable fields
   */
  get searchable(): DataTableSearchable[] {
    // Default searchable fields
    return [
      {
        key: 'id',
        name: '#',
      },
      {
        key: 'branch.name',
        name: this.$it('global.location', 'Location') as string,
      },
      {
        key: 'dealer.name',
        name: this.$it('global.dealer', 'Dealer') as string,
      },
    ];
  }

  /**
   * The DataTable columns
   */
  get columns(): DataTableColumn[] {
    return [
      {
        title: this.$it('dashboard.col.location.title', 'Location') as string,
        key: 'branch.name',
        sortable: true,
        class: 'data-table-col-min',
        width: 200,
      },
      {
        title: this.$it('snapshot.col.planning.title', 'Planning') as string,
        key: 'plannedAt',
        sortable: true,
        class: 'data-table-col-fixed',
        width: 100,
      },
      {
        title: this.$it('global.assets', 'Assets') as string,
        key: 'inspections.length',
        slot: 'inspectionAmount',
        class: 'data-table-col-fixed data-table-col-center',
        width: 80,
        sortable: 'inspectionsAmount',
      },
      {
        title: this.$it('audit.dashboard.last-audit.title', 'Last Audit') as string,
        key: 'branch.endedAudits',
        slot: 'branch.endedAudits',
        class: 'data-table-col-fixed data-table-col-center',
        width: 100,
      },
      {
        title: this.$it('equipment-situation.sold.title', 'Sold') as string,
        tooltip: {
          message: this.$it('equipment-situation.sold.title', 'Sold') as string,
        },
        key: 'sold',
        slot: 'equipmentSituation',
        class: 'data-table-col-fixed data-table-col-center',
        width: 60,
        sortable: 'soldEquipmentSituationAmount',
      },
      {
        title: this.$it('equipment-situation.rental.title', 'Rental') as string,
        tooltip: {
          message: this.$it('equipment-situation.rental.title', 'Rental') as string,
        },
        key: 'rental',
        slot: 'equipmentSituation',
        class: 'data-table-col-fixed data-table-col-center',
        width: 60,
        sortable: 'rentalEquipmentSituationAmount',
      },
      {
        title: this.$it('equipment-situation.demo.title', 'Demo') as string,
        tooltip: {
          message: this.$it('equipment-situation.demo.title', 'Demo') as string,
        },
        key: 'in_demonstration',
        slot: 'equipmentSituation',
        class: 'data-table-col-fixed data-table-col-center',
        width: 60,
        sortable: 'inDemonstrationEquipmentSituationAmount',
      },
      {
        title: this.$it('inspection.status.expired.title', 'Expired') as string,
        tooltip: {
          message: this.$it('inspection.status.expired.title', 'Expired') as string,
        },
        key: 'expiredInspections',
        slot: 'number',
        class: 'data-table-col-fixed data-table-col-center',
        width: 60,
        sortable: 'expiredStatusAmount',
      },
      {
        title: this.$it('audit.detail.anomalies-amount', 'Anomalies') as string,
        tooltip: {
          message: this.$it('audit.detail.anomalies-amount', 'Anomalies') as string,
        },
        key: 'inspectionsWithAnomaliesAmount',
        slot: 'anomalies',
        class: 'data-table-col-fixed data-table-col-center',
        width: 60,
        sortable: 'anomaliesAmount',
      },
      {
        title: this.$it('audit.detail.score', 'Score') as string,
        tooltip: {
          message: this.$it('audit.detail.score', 'Score') as string,
        },
        key: 'score',
        class: 'data-table-col-fixed data-table-col-center',
        width: 60,
        permission: 'inspections-score-get',
        sortable: true,
      },
      {
        title: this.$it('audit.detail.trusted', 'Trusted') as string,
        tooltip: {
          message: this.$it('audit.detail.trusted', 'Trusted') as string,
        },
        key: 'trusted',
        class: 'data-table-col-fixed data-table-col-center',
        width: 60,
      },
      {
        title: '',
        key: 'id',
        slot: 'buttons',
        class: 'data-table-col-fixed',
        width: 120,
      },
    ];
  }

  /**
   * The DataTable filters
   */
  get filters(): Filter[] {
    return [
      this.accountManagerFilter,
      {
        key: 'trusted',
        title: this.$it('audit.detail.trusted', 'Trusted') as string,
        type: FilterType.Boolean,
      },
    ];
  }

  private reviewAnomalies(audit: PaginateAudits_paginateAudits_data, situationTitle: string | null) {
    this.selected = audit;
    this.situationTitle = situationTitle;
    this.$nextTick(() => {
      (this.$refs.reviewAuditAction as ReviewAuditAction).open();
    });
  }

  private closeAudit(audit: PaginateAudits_paginateAudits_data) {
    this.selected = audit;
    this.$nextTick(() => {
      (this.$refs.closeAuditAction as CloseAuditAction).open();
    });
  }

  private approveInspection(inspection: any | any[], type: string) {
    this.selectedItem = inspection;
    if (type === 'accept') {
      this.$nextTick(() => {
        (this.$refs.acceptInspections as ApproveInspections).open();
      });
    } else if (type === 'decline') {
      this.$nextTick(() => {
        (this.$refs.declineInspections as ApproveInspections).open();
      });
    }
  }

  private toLastAudit(endedAudit: any) {
    if (endedAudit) {
      const routeData = this.$router.resolve({
        name: 'audits-detail',
        params: { auditId: endedAudit[0].id },
      });
      window.open(routeData.href, '_blank');
    }
  }

  @Meta
  private getMetaInfo() {
    return {
      title: this.$it('menu.audit.dashboard.title', 'Audit Dashboard') as string,
    };
  }
}
