










import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from '@/layouts/back-office/elements/Card.vue';
import {snapshot_inspection, snapshot_inspection_parameterValues} from '@/types/intrador';
import Polaroid from '@/components/Polaroid.vue';

@Component({
  components: {Polaroid, Card},
})
export default class SnapshotImages extends Vue {
  @Prop(Object) protected inspection!: snapshot_inspection;

  get hasImages() {
    if (!this.inspection || !this.inspection.parameterValues) {
      return false;
    }

    for (const i in this.inspection.parameterValues || []) {
      if (this.inspection.parameterValues[i]) {
        const pv = (this.inspection.parameterValues[i] as snapshot_inspection_parameterValues);
        if (pv && pv.images && pv.images.length > 0) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Open event
   *
   * @param id
   */
  private click(id: string) {
    this.$emit('open', id);
  }
}
