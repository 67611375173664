

























import { Component, Mixins, Prop } from 'vue-property-decorator';
import AnomalyViewMixin from '@/pages/audits/dashboard/AnomalyViewMixin';
import ImageViewer from '@/components/ImageViewer.vue';
import { snapshot_inspection } from '@/types/intrador';
import Polaroid from '@/components/Polaroid.vue';

@Component({
  components: {
    ImageViewer,
    Polaroid,
  },
})
export default class RentalAnomalyView extends Mixins(AnomalyViewMixin) {
  @Prop(Object) protected inspection!: snapshot_inspection;

  protected namespace: string = 'TimesInRental';
  protected message = 'TIMES_IN_RENTAL';
  protected level = 'ERROR';

  protected openImage(id: string) {
    this.$nextTick(() => {
      this.$emit('open', id);
    });
  }

  protected get rentalValue() {
    return this.inspection.parameterValues?.find((value) => value?.parameter?.id === '1132');
  }
}
