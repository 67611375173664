







import {Component, Prop, Vue} from 'vue-property-decorator';
import NumberCard from '@/components/dealer/cards/NumberCard.vue';
import {EquipmentSituation} from '@/types/intrador';

@Component({
  components: {
    NumberCard,
  },
})
export default class EquipmentSituationNumberCard extends Vue {
  @Prop({type: [Number, String]}) protected amount!: number | string;
  @Prop({type: Boolean, default: false}) protected bold!: boolean;
  @Prop({type: Boolean, default: false}) protected sold!: boolean;
  @Prop({type: Boolean, default: false}) protected demo!: boolean;
  @Prop({type: Boolean, default: false}) protected rental!: boolean;
  @Prop(String) protected title!: string;

  private get colorCode() {
    switch (true) {
      case this.sold:
        return this.$snapshot.equipmentSituation.getColor(EquipmentSituation.SOLD);
      case this.demo:
        return this.$snapshot.equipmentSituation.getColor(EquipmentSituation.IN_DEMONSTRATION);
      case this.rental:
        return this.$snapshot.equipmentSituation.getColor(EquipmentSituation.RENTAL);
    }
  }
}
