var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-audit-dashboard"},[(_vm.hasDefaultAccountManager)?_c('transition',{attrs:{"name":"fade"}},[(_vm.elementVisible && !('paginate-audits-filter-accountManagerIds' in _vm.$route.query))?_c('div',{staticClass:"alert alert-success center"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$it('audit.dashboard.default.account-manager', {accountManager: _vm.defaultAccountManager.name}, 'The audits in review for account manager {accountManager} are shown'))+" ")])]):_vm._e()]):_vm._e(),_c('query-data-table',{ref:"queryDataTable",attrs:{"data-key":"paginateAudits","title":_vm.$it('audit.dashboard', 'Audit Review Dashboard'),"query":require('@/graphql/PaginateAudits.gql'),"variables":_vm.variables,"columns":_vm.columns,"filters":_vm.filters,"searchableFields":_vm.searchable,"row-height":60,"buffer":600},on:{"update":_vm.onDataReceived},scopedSlots:_vm._u([{key:"data-table-prepend",fn:function(ref){
var pagination = ref.pagination;
return [_c('apollo-query',{ref:"query",attrs:{"query":require('@/graphql/AuditStatistics.gql'),"variables":_vm.statisticsVariables,"update":_vm.onStatisticsReceived},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
var query = ref.query;
var isLoading = ref.isLoading;
return [(loading || isLoading)?_c('spinner',{staticClass:"spinner",attrs:{"size":18,"color":"#838383"}}):_vm._e(),_c('div',{staticClass:"row"},[_c('h5',{staticClass:"statistics-title"},[_vm._v(_vm._s(_vm.$it('audit.progress.title', 'Audit Progress')))])]),(data)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(!isLoading)?_c('vue-frappe',{staticClass:"statistics",attrs:{"id":"chart","type":"axis-mixed","height":200,"colors":['#2197AE'],"yRegions":[{ label: '', start: 0, end: _vm.maxChartHeight}],"labels":[
                            _vm.$it('planning.swimlane.to-plan.title', 'To Plan'),
                            _vm.$it('planning.swimlane.in-progress.title', 'In Progress'),
                            _vm.$it('planning.swimlane.to-review.title', 'To Review') ],"spaceRatio":0.6,"dataSets":_vm.chartData,"tooltipOptions":{formatTooltipY: function (d) { return d + ' ' + _vm.$it('global.audits', 'Audits'); },}}}):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('number-card',{attrs:{"success":"","bold":"","amount":_vm.resolvedAmount ? _vm.resolvedAmount : 0,"title":_vm.$it('audit.dashboard.reviewed-this-month.title', 'Audits reviewed this month')}})],1),_c('div',{staticClass:"col"},[_c('equipment-situation-number-card',{attrs:{"sold":"","bold":"","amount":_vm.inSoldCount ? _vm.inSoldCount : 0,"title":_vm.$it('audit.dashboard.sold-assets-this-month.title', 'Sold assets this month')}})],1),_c('div',{staticClass:"col"},[_c('equipment-situation-number-card',{attrs:{"rental":"","bold":"","amount":_vm.inRentalCount ? _vm.inRentalCount : 0,"title":_vm.$it('audit.dashboard.rental-assets-this-month.title', 'Assets in rental this month')}})],1),_c('div',{staticClass:"col"},[_c('equipment-situation-number-card',{attrs:{"demo":"","bold":"","amount":_vm.inDemoCount ? _vm.inDemoCount : 0,"title":_vm.$it('audit.dashboard.demo-assets-this-month.title', 'Assets in demonstration this month')}})],1)])]}}],null,true)})]}},{key:"branch.name",fn:function(ref){
                          var value = ref.value;
                          var column = ref.column;
                          var item = ref.item;
return [_c('router-link',{staticClass:"branch-name",attrs:{"to":{name: 'branches-detail', params: {id: item.dealer.id, branchId: item.branch.id},
                     query: {return: 'audits-dashboard'}}}},[_vm._v(" "+_vm._s(value)+" ")]),_c('div',{staticClass:"row-subtitle"},[_c('meta-list',{attrs:{"balloon":"right","meta":item.branch.meta,"prepend":[{value: item.branch.externalId, tooltip: _vm.$it('global.location-id', 'Location Id')}],"keys":[item.branch.externalId || null, 'cnpj'],"tooltips":[_vm.$it('global.location-id', 'Location Id'), _vm.$it('global.cnpj', 'CNPJ')]}}),(item.isOnHold)?_c('on-hold-label'):_vm._e()],1)]}},{key:"plannedAt",fn:function(ref){
                     var value = ref.value;
                     var column = ref.column;
                     var item = ref.item;
return [(item.plannedAt && item.dueAt)?_c('div',{attrs:{"aria-label":((_vm.$id(item.plannedAt, 'time')) + " - " + (_vm.$id(item.dueAt, 'time'))),"data-balloon-pos":"left"}},[_c('div',{staticClass:"line"},[_vm._v(_vm._s(_vm.$id(item.plannedAt, 'date')))]),_c('div',{staticClass:"line"},[_vm._v(_vm._s(_vm.$id(item.dueAt, 'date')))])]):(item.plannedAt)?_c('div',{attrs:{"aria-label":("" + (_vm.$id(item.plannedAt, 'time'))),"data-balloon-pos":"left"}},[_c('div',{staticClass:"line"},[_vm._v(_vm._s(_vm.$id(item.plannedAt, 'date')))])]):_c('div',{attrs:{"aria-label":("" + (_vm.$id(item.createdAt, 'time'))),"data-balloon-pos":"left"}},[_c('div',{staticClass:"line"},[_vm._v(_vm._s(_vm.$id(item.createdAt, 'date')))])])]}},{key:"inspectionAmount",fn:function(ref){
                     var value = ref.value;
                     var column = ref.column;
                     var item = ref.item;
return [_c('router-link',{staticClass:"asset-number",attrs:{"aria-label":_vm.$it('view.assets.title', 'View Assets'),"data-balloon-pos":"left","tag":"button","to":{ name: 'audits-detail', params: { auditId: item.id }, query: { return: 'audits-dashboard' } }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"branch.endedAudits",fn:function(ref){
                     var value = ref.value;
                     var column = ref.column;
                     var item = ref.item;
return [(!value[0])?_c('div',[_vm._v(_vm._s(_vm.$it('global.na', 'N/A')))]):_c('div',{staticClass:"ended-audit",attrs:{"aria-label":_vm.$id(value[0].closedAt, 'date'),"data-balloon-pos":"left"},on:{"click":function($event){return _vm.toLastAudit(value)}}},[_c('last-audit-rating',{attrs:{"audit":value[0]}})],1)]}},{key:"number",fn:function(ref){
                     var value = ref.value;
                     var column = ref.column;
                     var item = ref.item;
return [_c('button',{staticClass:"amount",class:{ highlight: value > 0 },on:{"click":function($event){return _vm.reviewAnomalies(item, column.title)}}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"equipmentSituation",fn:function(ref){
                     var value = ref.value;
                     var column = ref.column;
                     var item = ref.item;
return [_c('button',{staticClass:"amount",class:{ highlight: _vm.inspectionAmount(item, column) > 0 },on:{"click":function($event){return _vm.reviewAnomalies(item, column.title)}}},[_vm._v(" "+_vm._s(_vm.inspectionAmount(item, column))+" ")])]}},{key:"score",fn:function(ref){
                     var value = ref.value;
                     var column = ref.column;
                     var item = ref.item;
return [_c('div',{staticClass:"score"},[_c('score',{attrs:{"score":value,"balloon-pos":"left"}})],1)]}},{key:"anomalies",fn:function(ref){
                     var value = ref.value;
                     var column = ref.column;
                     var item = ref.item;
return [_c('div',{staticClass:"anomalies-amount",on:{"click":function($event){return _vm.reviewAnomalies(item, column.title)}}},[_c('anomalies-amount',{attrs:{"status":item.status,"anomalies-amount":value}})],1)]}},{key:"trusted",fn:function(ref){
                     var value = ref.value;
                     var column = ref.column;
                     var item = ref.item;
return [(value)?_c('i',{staticClass:"fas fa-check-circle"}):_c('i',{staticClass:"fa fa-minus-circle"})]}},{key:"buttons",fn:function(ref){
                     var value = ref.value;
                     var column = ref.column;
                     var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('button',{staticClass:"icon",attrs:{"aria-label":_vm.$it('review-audit.title', 'Review Audit'),"data-balloon-pos":"left"},on:{"click":function($event){return _vm.reviewAnomalies(item)}}},[_c('i',{staticClass:"fa fa-search"})]),_c('router-link',{staticClass:"icon",attrs:{"to":{ name: 'audits-detail', params: { auditId: item.id }, query: { return: 'audits-dashboard' } },"aria-label":_vm.$it('audit.button.view-audit', 'View Audit'),"data-balloon-pos":"left"}},[_c('i',{staticClass:"fas fa-eye"})]),(_vm.$can('audits-close'))?_c('button',{staticClass:"icon",attrs:{"aria-label":_vm.$it('audit.button.close-audit', 'Close Audit'),"data-balloon-pos":"left"},on:{"click":function($event){return _vm.closeAudit(item)}}},[_c('i',{staticClass:"fa fa-check"})]):_vm._e()],1)]}}])}),(_vm.selected)?[_c('review-audit-action',{ref:"reviewAuditAction",attrs:{"audit":_vm.selected,"situation-title":_vm.situationTitle},on:{"closeAuditAction":function($event){return _vm.$refs.closeAuditAction.open()},"approveInspection":_vm.approveInspection}}),_c('close-audit-action',{ref:"closeAuditAction",attrs:{"selected":[_vm.selected],"audit-id":_vm.selected.id},on:{"done":_vm.refetch}}),(_vm.selectedItem)?[_c('approve-inspections',{ref:"acceptInspections",attrs:{"inspection-type":_vm.inspectionType,"selected":_vm.selectedItem,"audit-id":_vm.selected.id,"state":_vm.stateAccept},on:{"done":_vm.refetch}}),_c('approve-inspections',{ref:"declineInspections",attrs:{"inspection-type":_vm.inspectionType,"selected":_vm.selectedItem,"audit-id":_vm.selected.id,"state":_vm.stateDecline},on:{"done":_vm.refetch}})]:_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }